import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-list-column-order',
  templateUrl: './list-column-order.component.html',
  styleUrls: ['./list-column-order.component.scss']
})
export class ListColumnOrderComponent {

  @Input() public collection!: any[];
  @Input() public property!: string;
  @Input() public type: 'numeric' | 'date' | 'code' | 'text' = 'text';
  private order: 'ASC' | 'DESC' = 'ASC';

  public orderList(): void {

    this.order = this.order === 'ASC' ? 'DESC' : 'ASC';

    this.collection = this.collection.sort((elementBack: any, elementNext: any) => {
      switch (this.type) {
        case 'numeric':
          return this.getOrderValue(parseInt(elementBack[this.property]), parseInt(elementNext[this.property]));
        case 'date':
          return this.getOrderValue(new Date(elementBack[this.property]).getTime(), new Date(elementNext[this.property]).getTime());
        case 'text':
          return this.getOrderValue(elementBack[this.property], elementNext[this.property]);
        case 'code':
          let elementBackNumber: number = parseInt(elementBack[this.property].length > 0 ? elementBack[this.property][0][this.type] : -1);
          let elementNextNumber: number = parseInt(elementNext[this.property].length > 0 ? elementNext[this.property][0][this.type] : -1)
          return this.getOrderValue(elementBackNumber, elementNextNumber);
      }
    });
  }

  private getOrderValue(greaterThan: number | string, lessThan: number | string): number {
    if (greaterThan > lessThan) {
      return this.order === 'ASC' ? 1 : -1;
    } else {
      return this.order === 'ASC' ? -1 : 1;
    }
  }
}
