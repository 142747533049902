<div class="collapse navbar-collapse">
    <ul class="navbar-nav">
        <li class="nav-item" *ngIf="customerPlaceCurrentCode">
            <a class="nav-link fw-semibold text-white cursor-pointer small" *ngIf="customerPlaceCurrentName"
                (click)="customerPlaceSelection()" [title]="'goToCustomerPlaceSelection' | translate">
                {{customerPlaceCurrentName}}
            </a>
        </li>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'boxes',
            title: 'managementContents' | translate,
            tooltip: 'contents' | translate,
            link: '/manager/customer-place/' + customerPlaceCurrentCode + '/content/list',
            show: customerPlaceCurrentCode !== null
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'map',
            title: 'managementMaps' | translate,
            tooltip: 'maps' | translate,
            link: '/manager/customer-place/' + customerPlaceCurrentCode + '/map/list',
            show: customerPlaceCurrentCode !== null
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'diagram-3',
            title: 'managementTours' | translate,
            tooltip: 'tours' | translate,
            link: '/manager/customer-place/' + customerPlaceCurrentCode + '/tour/list',
            show: customerPlaceCurrentCode !== null
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'cloud-haze2',
            title: 'managementPublishes' | translate,
            tooltip: 'publish' | translate,
            link: '/manager/customer-place/' + customerPlaceCurrentCode + '/publish/list',
            show: customerPlaceCurrentCode !== null
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'translate',
            title: 'managementTranslationFiles' | translate,
            tooltip: 'translationsFiles' | translate,
            link: '/manager/customer-place/' + customerPlaceCurrentCode + '/translations-files/clients-list',
            show: customerPlaceCurrentCode !== null
        }"></ng-container>
        <li class="nav-item small" *ngIf="customerPlaceCurrentCode !== null">
            <a class="nav-link text-white mx-2"
                [routerLink]="'/manager/customer-place/' + customerPlaceCurrentCode + '/client-alerts/list'"
                [ngbTooltip]="'clientAlerts' | translate" [title]="'managementClientAlerts' | translate"
                placement="bottom" tooltipClass="tooltip-custom">
                <!-- Este icono  no sigue la estructura de la app porque es de materials -->
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#e8eaed">
                    <path
                        d="M480-360q17 0 28.5-11.5T520-400q0-17-11.5-28.5T480-440q-17 0-28.5 11.5T440-400q0 17 11.5 28.5T480-360Zm-40-160h80v-240h-80v240ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z" />
                </svg>
            </a>
        </li>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'bell',
            title: 'notificationsPush' | translate,
            tooltip: 'notificationsPush' | translate,
            link: '/manager/customer-place/' + customerPlaceCurrentCode + '/notification-push/list',
            show: customerPlaceCurrentCode !== null
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'key',
            title: 'adminArea' | translate,
            tooltip: 'adminArea' | translate,
            link: '/admin/users/list',
            show: isAdmin
        }"></ng-container>
    </ul>
</div>
<ng-template #listElement let-icon="icon" let-title="title" let-tooltip="tooltip" let-link="link" let-show="show">
    <li class="nav-item small" *ngIf="show">
        <a class="nav-link text-white mx-2" [routerLink]="link" [ngbTooltip]="tooltip" [title]="title"
            placement="bottom" tooltipClass="tooltip-custom">
            <i-bs [name]="icon" width="22" height="22"></i-bs>
        </a>
    </li>
</ng-template>